<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('warehouse_report.productStockReport') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
            <b-row v-if="!authUser.warehouse_id">
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      label-for="division_id"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('warehouse_report.division')}} <span class="text-danger">*</span>
                </template>
                    <b-form-select
                        plain
                        v-model="search.division_id"
                        :options="divisionList"
                        id="division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Region" vid="region_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      label-for="region_id"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                </template>
                    <b-form-select
                        plain
                        v-model="search.region_id"
                        :options="regionList"
                        id="division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      :label="$t('warehouse_report.district')"
                      label-for="district_id"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                        {{ $t('warehouse_report.district')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="search.district_id"
                        :options="districtList"
                        id="district_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      :label="$t('warehouse_report.upazilla')"
                      label-for="upazilla_id"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('warehouse_report.upazilla')}} <span class="text-danger">*</span>
                </template>
                    <b-form-select
                        plain
                        v-model="search.upazilla_id"
                        :options="upazilaList"
                        id="upazilla_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Union" vid="union_id" rules="">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      :label="$t('globalTrans.union')"
                      label-for="union_id"
                      slot-scope="{ valid, errors }"
                  >
                    <b-form-select
                        plain
                        v-model="search.union_id"
                        :options="unionList"
                        id="union_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Warehouse Type" vid="warehouse_type_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      label-for="warehouse_type_id"
                      :label="$t('warehouse_config.warehouse_type')"
                      slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                        {{ $t('warehouse_config.warehouse_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="search.warehouse_type_id"
                        :options="warehouseTypeList"
                        id="warehouse_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Warehouse" vid="warehouse_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      label-for="warehouse_id"
                      :label="$t('warehouse_information.warehouse_name')"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('warehouse_information.warehouse_name')}} <span class="text-danger">*</span>
                </template>
                    <b-form-select
                        plain
                        v-model="search.warehouse_id"
                        :options="warehouseList"
                        id="commodity_group_id"
                        v-if="!authUser.warehouse_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-select
                        plain
                        v-model="search.warehouse_id"
                        :options="warehouseList"
                        id="warehouse_id"
                        v-else
                    >
                    </b-form-select>
                    <div v-if="!authUser.warehouse_id" class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Farmer" vid="farmer_id" rules="required|min_value:1">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  label-for="farmer_id"
                  :label="$t('warehouse_report.farmer')"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('warehouse_report.farmer')}} <span class="text-danger">*</span>
                </template>
                  <b-form-select
                      plain
                      v-model="search.farmer_id"
                      :options="farmerListEn"
                      id="farmer_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      v-if="currentLocale === 'en'"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ farmerLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <b-form-select
                      plain
                      v-model="search.farmer_id"
                      :options="farmerListBn"
                      id="farmer_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      v-else
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
  <!--                <b-form-select-->
  <!--                    plain-->
  <!--                    v-model="search.farmer_id"-->
  <!--                    :options="farmerDropDownList"-->
  <!--                    id="farmer_id"-->
  <!--                    v-else-->
  <!--                >-->
  <!--                </b-form-select>-->
                      <div v-if="!search.farmer_id" class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    label-for="crop_type_id"
                    :label="$t('warehouse_report.crop_type')"
                >
                  <b-form-select
                      plain
                      v-model="search.crop_type_id"
                      :options="cropTypeList"
                      id="crop_type_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    label-for="crop_id"
                    :label="$t('warehouse_report.crop_name')"
                >
                  <b-form-select
                      plain
                      v-model="search.crop_id"
                      :options="cropNameDropDownList"
                      id="crop_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <b-button type="submit" variant="primary" class="float-right">{{ $t('globalTrans.submit')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.productStockReport') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="headerInfo"
                  worksheet="Product Stock Report Sheet"
                  name="product-stock-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row v-show="!loading">
                   <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('warehouse_report.productStockReport')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_service.farmer_name') }}: </strong> {{ getFarmerName(search.farmer_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.crop_type') }}: </strong> {{ getCropTypeName(search.commodity_group_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.crop_name') }}: </strong> {{ getCropName(search.crop_id) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-th style="width:5%">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th style="width:15%">{{ $t('warehouse_report.crop_type') }}</b-th>
                            <b-th style="width:15%">{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th class="text-right" style="width:15%">{{ $t('warehouse_report.stockQty') }}</b-th>
                            <b-th class="text-right" style="width:15%">{{ $t('warehouse_report.releaseQty') }}</b-th>
                            <b-th class="text-right" style="width:15%">{{ $t('warehouse_report.availableQty') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                           <template v-for="(cropTypeInfo, cropTypeId, cropTypeIndex) in wareReportData">
                             <template v-for="(cropNameInfo, cropNameId, indx) in cropTypeInfo" >
                                  <b-tr :key="cropTypeIndex + indx + 200" v-if="indx">
                                    <b-th v-if="indx"><span class="capitalize">{{ typeof cropNameList.find(item => item.value == cropNameId) != 'undefined' ? (currentLocale === 'en' ? cropNameList.find(item => item.value == cropNameId).text : cropNameList.find(item => item.value == cropNameId).text_bn) : '' }}</span></b-th>
                                    <b-td v-if="indx" class="text-right">{{ $n(cropNameInfo.stock_qty) }}</b-td>
                                    <b-td v-if="indx" class="text-right">{{ $n(cropNameInfo.release_qty) }}</b-td>
                                    <b-td v-if="indx" class="text-right">{{ $n(cropNameInfo.available_qty) }}</b-td>
                                  </b-tr>
                                  <b-tr :key="cropTypeIndex + indx + 999" v-else class="dfs">
                                    <b-th :rowspan="Object.keys(cropTypeInfo).length+1">{{ cropTypeIndex+1 }}</b-th>
                                    <b-th :rowspan="Object.keys(cropTypeInfo).length+1"><span class="capitalize">{{ typeof cropTypeList.find(item => item.value == cropTypeId) != 'undefined' ? (currentLocale === 'en' ? cropTypeList.find(item => item.value == cropTypeId).text : cropTypeList.find(item => item.value == cropTypeId).text_bn) : '' }}</span></b-th>
                                    <b-th v-if="!indx"><span class="capitalize">{{ typeof cropNameList.find(item => item.value == cropNameId) != 'undefined' ? (currentLocale === 'en' ? cropNameList.find(item => item.value == cropNameId).text : cropNameList.find(item => item.value == cropNameId).text_bn) : '' }}</span></b-th>
                                    <b-td v-if="!indx" class="text-right">{{ $n(cropNameInfo.stock_qty) }}</b-td>
                                    <b-td v-if="!indx" class="text-right">{{ $n(cropNameInfo.release_qty) }}</b-td>
                                    <b-td v-if="!indx" class="text-right">{{ $n(cropNameInfo.available_qty) }}</b-td>
                                  </b-tr>
                              </template>
                             <b-tr :key="cropTypeIndex + 200 + 9999">
                               <b-th class="text-right"><span class="capitalize">{{ $t('sitePreference.total') }}</span></b-th>
                               <b-td class="text-right">{{ $n(cropTypeTotalInfo[cropTypeId].stock_qty) }}</b-td>
                               <b-td class="text-right">{{ $n(cropTypeTotalInfo[cropTypeId].release_qty) }}</b-td>
                               <b-td class="text-right">{{ $n(cropTypeTotalInfo[cropTypeId].available_qty) }}</b-td>
                             </b-tr>
                           </template>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr variant="secondary" >
                            <b-th colspan="3" class="text-right">
                              {{ $t('sitePreference.grossTotal') }}
                            </b-th>
                            <b-th class="text-right">{{ $n(totalStockQty) }}</b-th>
                            <b-th class="text-right">{{ $n(totalReleaseQty) }}</b-th>
                            <b-th class="text-right">{{ $n(totalAvailableQty) }}</b-th>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, productStockReportApi } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import excel from 'vue-excel-export'
import Vue from 'vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
pdfMake.vfs = pdfFonts.pdfMake.vfs
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'Crop Type': 'crop_type_custom',
  'Crop Name': 'crop_name_custom',
  'Stock Qty(Quintal)': 'stock_qty_custom',
  'Release Qty(Quintal)': 'release_qty_custom',
  'Available Qty(Quintal)': 'available_qty_custom'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'শস্যের ধরণ': 'crop_type_custom',
  'শস্য নাম': 'crop_name_custom',
  'স্টক পরিমাণ(কুইন্টাল)': 'stock_qty_custom',
  'রিলিজ পরিমাণ(কুইন্টাল)': 'release_qty_custom',
  'প্রাপ্য পরিমাণ(কুইন্টাল)': 'available_qty_custom'
}

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  name: 'UiDataTable',
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        farmer_id: 0,
        crop_type_id: 0,
        crop_id: 0
      },
      wareReportData: [],
      wareReportList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      warehouseList: [],
      warehouseDetails: [],
      tempList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      farmerListEn: [],
      farmerListBn: [],
      farmerListEnInitial: [],
      farmerListBnInitial: [],
      farmerDropDownList: [],
      cropTypeTotalInfo: {},
      totalStockQty: 0,
      totalReleaseQty: 0,
      totalAvailableQty: 0,
      reportListData: [],
      cropNameDropDownList: [],
      districtLoading: false,
      farmerLoading: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    json_fields: function () {
      return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeExcel () {
      // var serial = 0
      const customizeData = this.reportListData.map(item => {
        if (this.currentLocale === 'bn') {
          item.crop_type_custom = item.crop_type_name_bn
          item.crop_name_custom = item.crop_name_bn
        } else {
          item.crop_type_custom = item.crop_type_name
          item.crop_name_custom = item.crop_name
        }
        item.serial = item.serial_no ? this.$n(item.serial_no) : ''
        item.stock_qty_custom = this.$n(item.stock_qty)
        item.release_qty_custom = this.$n(item.release_qty)
        item.available_qty_custom = this.$n(item.available_qty)
        return Object.assign({}, item)
      })
      const item = {}
      item.crop_name_custom = this.$t('sitePreference.grossTotal')
      item.stock_qty_custom = this.$n(this.totalStockQty)
      item.release_qty_custom = this.$n(this.totalReleaseQty)
      item.available_qty_custom = this.$n(this.totalAvailableQty)

      customizeData.push(item)

      return customizeData
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id) + this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id)
      const secondRow = this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id) + this.$t('warehouse_service.farmer_name') + ' : ' + this.getFarmerName(this.search.farmer_id)
      const thirdRow = this.$t('warehouse_config.crop_type') + ' : ' + this.getCropTypeName(this.search.commodity_group_id) + ' ' + this.$t('warehouse_config.crop_name') + ' : ' + this.getCropName(this.search.crop_id)
      return [this.$t('warehouse_report.productStockReport'), '', firstRow, secondRow, thirdRow, '']
    },
    regionList: function () {
      return this.$store.state.warehouse.regionList
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    organizationtList: function () {
      const listObject = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return listObject
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    cropTypeList: function () {
      return this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
    },
    cropNameList: function () {
      return this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
      this.districtList = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    },
    'search.warehouse_id': function (newVal, oldVal) {
      this.getFarmerDropdownList(newVal)
    },
    'search.crop_type_id': function (newVal, oldVal) {
      this.cropNameDropDownList = this.getCropNameList(newVal)
    }
  },
  created () {
    // this.loadData()
    this.getFarmerList()
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  methods: {
    searchData () {
      // if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.showData = true
        this.showHeading = true
      // }
      // this.loading = false
    },
    getCropNameList (cropTypeId = null) {
      const cropNameList = this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)

      if (cropTypeId) {
        return cropNameList.filter(item => item.commodity_group_id === cropTypeId)
      }

      return cropNameList
    },
    isEmpty (obj) {
      return Object.keys(Object.keys(obj).length === 0 && obj.constructor === Object).length === 0 && Object.keys(obj).length === 0 && obj.constructor === Object.constructor === Object
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      RestApi.getData(warehouseServiceBaseUrl, productStockReportApi, this.search).then(response => {
        const listData = []
        if (response.success) {
          this.wareReportData = response.data
          const obj = response.data
          let totalStockQty = 0
          let totalReleaseQty = 0
          let totalAvailableQty = 0
          if (!this.isEmpty(obj)) {
            let serial = 1
            for (const cropTypeId in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, cropTypeId)) {
                const itemArr = obj[cropTypeId]
                const itemData = []
                let itemNo = 1
                let cropNameTotalStockQty = 0
                let cropNameTotalReleaseQty = 0
                let cropNameTotalAvailableQty = 0
                for (const cropNameId in itemArr) {
                  if (Object.prototype.hasOwnProperty.call(itemArr, cropNameId)) {
                    const nestedItem = itemArr[cropNameId]
                    const listItem = {}
                    listItem.serial_no = itemNo === 1 ? serial : ''

                    const cropTypeObj = this.cropTypeList.find(item => item.value === parseInt(cropTypeId))
                    if (typeof cropTypeObj !== 'undefined' && itemNo === 1) {
                      listItem.crop_type_name = cropTypeObj.text_en
                      listItem.crop_type_name_bn = cropTypeObj.text_bn
                    } else {
                      listItem.crop_type_name = ''
                      listItem.crop_type_name_bn = ''
                    }
                    const cropNameObj = this.cropNameList.find(item => item.value === parseInt(cropNameId))
                    if (typeof cropNameObj !== 'undefined') {
                      listItem.crop_name = cropNameObj.text_en
                      listItem.crop_name_bn = cropNameObj.text_bn
                    } else {
                      listItem.crop_name = ''
                      listItem.crop_name_bn = ''
                    }
                    itemData[cropNameId] = nestedItem
                    totalStockQty += nestedItem.stock_qty
                    cropNameTotalStockQty += nestedItem.stock_qty
                    totalReleaseQty += nestedItem.release_qty
                    cropNameTotalReleaseQty += nestedItem.release_qty
                    totalAvailableQty += nestedItem.available_qty
                    cropNameTotalAvailableQty += nestedItem.available_qty
                    listItem.stock_qty = nestedItem.stock_qty
                    listItem.release_qty = nestedItem.release_qty
                    listItem.available_qty = nestedItem.available_qty

                    listData.push(listItem)
                    itemNo++
                  }
                }
                this.cropTypeTotalInfo[cropTypeId] = {
                  stock_qty: cropNameTotalStockQty,
                  release_qty: cropNameTotalReleaseQty,
                  available_qty: cropNameTotalAvailableQty
                }
                const cropTypeTotalRowObj = {}
                cropTypeTotalRowObj.crop_name = 'Total'
                cropTypeTotalRowObj.crop_name_bn = 'মোট'
                cropTypeTotalRowObj.stock_qty = cropNameTotalStockQty
                cropTypeTotalRowObj.release_qty = cropNameTotalReleaseQty
                cropTypeTotalRowObj.available_qty = cropNameTotalAvailableQty

                listData.push(cropTypeTotalRowObj)
              }
              serial++
            }
          }
          this.totalStockQty = totalStockQty
          this.totalReleaseQty = totalReleaseQty
          this.totalAvailableQty = totalAvailableQty
          this.reportListData = listData
        } else {
          this.wareReportData = {}
          this.totalStockQty = 0
          this.totalReleaseQty = 0
          this.totalAvailableQty = 0
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }).catch(error => {
        if (error) {
          //
        }
        this.wareReportList = []
      })
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
      this.districtLoading = false
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    async getFarmerList () {
      this.farmerLoading = true
        const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          const farmerList = response.data
          // farmerList.filter(item => {
          //   const newItem = {}
          //   newItem.value = item.mobile_no
          //   newItem.text = this.currentLocale === 'en' ? item.name : item.name_bn
          //   newItem.text_en = item.name
          //   newItem.text_bn = item.name_bn
          //
          //   this.farmerDropDownList.push(newItem)
          // })
          this.farmerListEnInitial = farmerList.map(obj => {
            return { value: obj.mobile_no, text: obj.name, warehouse_id: obj.warehouse_id }
          })
          this.farmerListBnInitial = farmerList.map(obj => {
            return { value: obj.mobile_no, text: obj.name_bn, warehouse_id: obj.warehouse_id }
          })
          if (this.authUser.warehouse_id) {
            this.farmerListEn = this.farmerListEnInitial.filter(item => item.warehouse_id === this.authUser.warehouse_id)
            this.farmerListBn = this.farmerListBnInitial.filter(item => item.warehouse_id === this.authUser.warehouse_id)
          }
        }
      this.farmerLoading = false
    },
    getFarmerDropdownList (warehouseId) {
      this.farmerListEn = this.farmerListEnInitial.filter(item => item.warehouse_id === warehouseId)
      this.farmerListBn = this.farmerListBnInitial.filter(item => item.warehouse_id === warehouseId)
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.stockQty'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.releaseQty'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.availableQty'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Product Stock Report' : 'পণ্য স্টক প্রতিবেদন'
      const columnWids = ['7%', '20%', '20%', '15%', '15%', '15%']
      const header1 = [
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_service.farmer_name')} : ${this.getFarmerName(this.search.farmer_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header3 = [
        { text: `${this.$t('warehouse_config.crop_type')} : ${this.getCropTypeName(this.search.commodity_group_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.crop_name')} : ${this.getCropName(this.search.crop_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2, header3]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData, 'product-stock-report')
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_name' : 'crop_type_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: 'stock_qty' },
          { key: 'release_qty' },
          { key: 'available_qty' }
        ]
      // var serial = 0
      const listData = this.reportListData.map(item => {
        // serial += 1
        const rowData = keys.map((keyItem, index) => {
          if (keyItem.key === 'serial_no') {
              return { text: item[keyItem.key] ? this.$n(item[keyItem.key]) : '' }
          }
          if (['stock_qty', 'release_qty', 'available_qty'].includes(keyItem.key)) {
            return { text: this.$n(item[keyItem.key]) }
          }

          return { text: item[keyItem.key] }
        })
        return rowData
      })

      const totalItem = [
        { text: '' },
        { text: '' },
        this.currentLocale === 'en' ? { text: 'Gross Total :' } : { text: 'সর্ব মোট :' },
        { text: this.$n(this.totalStockQty) },
        { text: this.$n(this.totalReleaseQty) },
        { text: this.$n(this.totalAvailableQty) }
      ]
      listData.push(totalItem)

      return listData
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getFarmerName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const objEn = this.farmerListEn.find(item => item.value === id)
        const objBn = this.farmerListBn.find(item => item.value === id)
        nameEn = objEn?.text
        nameBn = objBn?.text
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getCropTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.commodityGroupList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getCropName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.commodityNameList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
